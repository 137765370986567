<script setup lang='ts'>
const props = withDefaults(defineProps<{
  title?: string
  leftBorder?: boolean
  bottomBorder?: boolean
  borderColor?: string
  isShowAfter?: boolean
  url?: string
  size?: string
}>(), {
  title: '',
  leftBorder: true,
  bottomBorder: false,
  borderColor: '',
  isShowAfter: false,
  url: '/',
  size: '24',
})
</script>

<template>
  <div class="flex items-center">
    <slot name="before">
      <div class="flex-shrink-0 self-stretch" :class="{ 'left-border': leftBorder }" :style="[borderColor ? `--color:${borderColor};` : '']" />
    </slot>
    <slot>
      <div
        class="title line-clamp-1 flex-shrink-0"
        :style="{ fontSize: `${size}px` }" :class="[{ 'border-bottom': props.bottomBorder }]"
      >
        {{ title }}
      </div>
    </slot>
    <div v-if="$slots.after || isShowAfter" class="ml-2.5 flex-1 overflow-hidden">
      <slot name="after">
        <NuxtLink :to="url" class="flex items-center justify-end cursor-pointer text-3.5 hover:text-orange-6">
          <span>更多</span>
          <i class="i-material-symbols:keyboard-double-arrow-right text-1.3em" />
        </NuxtLink>
      </slot>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.left-border {
  --color: theme(colors.blue.500);
  --uno: bg-[var(--color)] w-2 h-auto rounded-full mr-3;
}
.title {
  --uno: font-bold text-gray-8 tracking-0.5 leading-tight;

  &.border-bottom {
    --uno: w-full  min-h-10 border-b-2 border-gray-2;
  }
}
</style>
